//font color
$white: #fff;
$gray100: #f6f8f9;
$gray300: #c0c6cb;
$gray600: #a0a4a7;
$gray900: #787c7f;
$black: #242729;

//line color
$l-white: #fff;
$l-light: #f7f7fb;
$l-regular: #e5e5ec;
$l-disbled: #cacad7;
$l-black: #111;

//background color
$bg-white: #fff;
$bg-light: #f7f7fb;
$bg-regular: #f1f1f5;
$bg-medium: #e1e1e1;
$bg-dark: #44444f;
$bg-disabled: #cacad7;
$bg-black: #111111;

//primary
$primary: #00babb;
$primary-strong: #00a9aa;
$primary-heavy: #008485;
$primary-sub1: #008485;
$primary-sub2: #008485;
