#main {
    * {
        box-sizing: border-box;
    }
    img {
        max-width: 100%;
        &.center-block {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .container {
        width: 1320px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;

        &.fluid {
            width: 100%;
        }
        &.narrow {
            width: 1200px;
        }
    }
}
