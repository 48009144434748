@import "./_font", "./_color";
#prototype {
    #nav {
        z-index: 1000;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 80px;
        align-items: center;
        border-bottom: 1px solid transparent;
        // border-bottom: 1px solid $l-regular;
        transition: all 0.6s ease;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100vw;
            height: 100%;
            transform: translate3d(0, -100%, 0);
            transition: all 0.6s ease;
        }

        .container {
            z-index: 100;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        #logo {
            img {
                height: 28px;
            }
        }

        #menu {
            display: flex;
            gap: 28px;
            li {
                transform: translate3d(0, -20px, 0);
                opacity: 0;
                transition: all 0.6s ease-in-out;

                button {
                    color: $f-black;
                    text-decoration: none;
                    @include title4;
                    border: 0;
                    background: none;
                }
            }
        }

        &.on {
            #menu {
                li {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
            }
            &.scrolling {
                height: 50px;
                border-bottom: 1px solid $l-regular;
                &::before {
                    transform: translate3d(0, 0, 0);
                    background: #fff;
                }
            }
        }
    }
}
