@font-face {
    font-family: "DM Sans";
    src: url("./DMSans-VariableFont_opsz,wght.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: "DM Sans";
    src: url("./DMSans-Italic-VariableFont_opsz,wght.ttf") format("truetype");
    font-weight: 100 900;
    font-style: italic;
}
