@import "./_font", "./_color";
@import "material-icons/iconfont/material-icons.scss";
#prototype {
    .tab-section {
        display: flex;
        justify-content: space-between;
        // padding: 0 120px;
        gap: 60px;

        .img-box {
            flex: 1;
            position: relative;
            border-radius: 20px;
            background: $bg-light;
            aspect-ratio: 16/15;
            overflow: hidden;

            // 이미지 슬라이드 애니메이션
            .image-slide {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.6s ease-in-out;
                opacity: 0;

                &:nth-child(1) {
                    background: #f06262;
                }
                &:nth-child(2) {
                    background: #62b7f0;
                }
                &:nth-child(3) {
                    background: #f0ae62;
                }
                &.active {
                    opacity: 1;
                }
            }
        }

        .txt-box {
            flex: 1;

            // 탭 메뉴 스타일
            .tabs {
                position: relative;
                display: flex;
                width: fit-content;
                margin-bottom: 20px;
                padding: 8px 12px;
                border-radius: 36px;
                gap: 8px;
                background: $bg-regular;

                .tab-button {
                    z-index: 10;
                    position: relative;
                    width: 86px;
                    padding: 8px 16px;
                    border-radius: 10px;
                    @include body2;
                    color: $f-gray;
                    border: none;
                    background: transparent;
                    transition: all 0.3s ease-in-out;

                    &.active {
                        color: #fff;
                    }
                }

                .active-bg {
                    z-index: 0;
                    position: absolute;
                    left: 12px;
                    top: 8px;
                    width: 86px;
                    height: 36px;
                    border-radius: 36px;
                    background-color: $bg-black;
                    transition: all 0.3s ease-in-out;
                }
            }

            .section-title,
            .sub-title {
                text-align: left;
            }

            // 리스트 스타일
            ul {
                display: flex;
                flex-flow: column;
                gap: 12px;
                li {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    @include title2;

                    &::before {
                        content: "\e5ca";
                        display: block;
                        border-radius: 50%;
                        font-family: "Material Icons";
                    }
                }
            }
        }
    }
}
