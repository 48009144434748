@import "./_font", "./_color";

.swiper-container {
    position: relative;
    .card-wrapper {
        display: flex;
        gap: 20px;
        transition: transform 0.5s ease;
        overflow: hidden;
        width: fit-content;
    }

    .card {
        position: relative;
        width: 590px;
        aspect-ratio: 5 / 4;
        border-radius: 12px;
        background: $bg-light;
        overflow: hidden;

        .txt-box {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 40px;
            .card-title {
                margin-bottom: 16px;
                @include title2;
                font-weight: 500;
            }
            .card-description {
                @include body1;
                color: $f-gray;
            }
        }
    }

    .controller {
        display: flex;
        gap: 8px;
        margin: 20px 0 0 auto;
        width: fit-content;
        .nav-button {
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            border: 0;
            color: #fff;
            background-color: $bg-dark;
            opacity: 1;

            &:hover {
                opacity: 0.8;
            }

            &:disabled {
                opacity: 1;
                background-color: $bg-disabled;
            }
        }
    }
}
