//font color
$f-white: #fff;
$f-disabled: #999;
$f-lightgray: #767676;
$f-gray: #505050;
$f-black: #111;

//line color
$l-white: #fff;
$l-light: #f7f7fb;
$l-regular: #e5e5ec;
$l-disbled: #cacad7;
$l-black: #111;

//background color
$bg-white: #fff;
$bg-light: #f7f7fb;
$bg-regular: #f1f1f5;
$bg-medium: #e1e1e1;
$bg-dark: #44444f;
$bg-disabled: #cacad7;
$bg-black: #111111;

//primary
$primary: #00babb;
$primary-strong: #00a9aa;
$primary-heavy: #008485;
$primary-sub1: #008485;
$primary-sub2: #008485;
