@import "./_font", "./_color";

.scroll-text-wrap {
    display: flex;
    width: 100%;
    height: 200px;
    overflow: hidden;
    .scroll-text {
        padding-right: 80px;
        flex-basis: 800px;
        min-width: 800px;
        max-width: 100%;
        min-height: fit-content;
        transition: all 0.8s ease;
        .word {
            @include headline1;
            font-weight: bold;
            opacity: 0.2;
            filter: blur(2px);
            transition: all 0.6s ease;
            &.on {
                opacity: 1;
                filter: blur(0px);
            }
        }
    }
    .divider {
        flex-basis: 4px;
        min-width: 4px;
        height: 100%;
        background: $l-black;
    }
    .main-text {
        padding-left: 80px;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 200px;
        font-weight: bold;
        filter: blur(2px);
        transition: all 0.8s ease;
    }

    &.active {
        .scroll-text {
            opacity: 0;
            min-width: 0;
            flex-basis: 0;
            padding-right: 0;
            visibility: hidden;
        }

        .main-text {
            // min-width: 100%;
            // flex-basis: 100%;
            filter: blur(0px);
            opacity: 1;
        }
    }
}
