#prototype {
    * {
        box-sizing: border-box;
    }
    #body-layout {
        padding-top: 81px;
    }
    .container {
        width: 1240px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;

        &.fluid {
            width: 100%;
        }
    }
}
