@import "./_font", "./_color";

/* Slider Container */
.slider {
    position: relative;
    margin: 0 auto;
}

/* Slides */
.slides {
    display: flex;
    gap: 20px;
    transition: transform 0.5s ease-in-out;
}

.slide {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column;
    opacity: 0.4;
    transition: all 0.6s ease;

    &:hover {
        opacity: 0.6;
    }
    &.active {
        // transform: translateX(0);
        opacity: 1;
    }

    .txt-box {
        position: relative;
        width: 100%;
        padding: 0 20px 32px;
        cursor: pointer;

        /* Indicator */
        .indicator {
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .progress-bar {
            width: 100%;
            background: $bg-black;
            transition: width 0.1s linear;
        }
    }
    .title {
        margin-bottom: 12px;
        @include sub-title;
        font-weight: bold;
    }
    .description {
        @include body1;
    }
}

.slider {
    .img-box {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
        .img {
            opacity: 0;
            z-index: 0;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            background-color: $bg-dark;
            transform: translate3d(0, 20px, 0);
            transition: all 0.6s ease;

            &.img1 {
                background-color: #f06262;
            }
            &.img2 {
                background-color: #62b7f0;
            }
            &.img3 {
                background-color: #f0ae62;
            }
            &.img4 {
                background-color: #66d46c;
            }

            &.active {
                z-index: 10;
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
        }
    }

    &.slider2 {
        position: relative;
        /* Indicator */
        .indicator {
            z-index: 30;
            position: absolute;
            left: 50%;
            bottom: 40px;
            transform: translate3d(-50%, 0, 0);
            width: 200px;
            height: 4px;
            border-radius: 4px;
            background-color: $gray100;
            overflow: hidden;
        }

        .progress-bar {
            height: 100%;
            background: $primary;
            transition: width 0.1s linear;
        }
        .img-box {
            .img {
                transform: translate3d(0, 0, 0);
                transition: all 1s ease;
                aspect-ratio: 1920/1080;
                background-repeat: no-repeat;
                background-size: cover;
                background-attachment: fixed !important;
                &.active {
                    z-index: 10;
                    opacity: 1;
                    transition: all 3s ease;
                }
            }
        }
    }
}

/* Controls */
.controls {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    // position: absolute;
    // bottom: 10px;
    // right: 10px;
    display: none;
}

.controls button {
    padding: 5px 10px;
    font-size: 14px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

@media (max-width: 767px) {
    #main {
        .slider {
            .img-box {
                aspect-ratio: initial;
                width: 100dvw;
                height: 100dvh;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
